export function getMarkup(message, username, color) {
    return `<button style="background:${color};font-size:1rem;display:flex;align-items:center;font-weight:400;line-height:1.5;color:#fff;text-align:center;text-decoration:none;cursor:pointer;user-select:none;border:1px solid transparent;padding: .375rem 1.25rem .375rem .75rem;border-radius:.25rem;"onclick="openWin()"><img style="width:32px;height:32px;margin-right:1rem;"src="https://svgur.com/i/Zry.svg"/>${message}</button><script>function openWin(){window.focus&&window.open("https://revolut.me/${username}",null,["width=500","height=600","top="+(window.screen.height-600)/2,"left="+(window.screen.width-500)/2,"directories=no","location=no","menubar=no","resizable=no","scrollbars=no","status=no","toolbar=no"].join(",")).focus()}</script>`
}

export function preview(username) {
    let url = `https://revolut.me/${username}`
    let width  = 500;
    let height = 600;
    let left   = (window.screen.width  - width)/2;
    let top    = (window.screen.height - height)/2;

    if (window.focus) {
         window.open(url, null, [
            `width=${width}`,
            `height=${height}`,
            `top=${top}`,
            `left=${left}`,
            'directories=no',
            'location=no',
            'menubar=no',
            'resizable=no',
            'scrollbars=no',
            'status=no',
            'toolbar=no'
        ].join(',')).focus();
    }
}