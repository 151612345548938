import React from 'react';
import '../Styles/Preview.scss';

import { getMarkup, preview } from '../Helpers/helpers';
import { CopyBlock, nord  } from "react-code-blocks";

export default function Preview(props) {
    const [markup, setMarkup] = React.useState(getMarkup(props.message, props.username, props.color));

    React.useEffect(() => {
        setMarkup(getMarkup(props.message, props.username, props.color));
    }, [props.username, props.message, props.color]);

    return <div>
        <p className="form-label-sm mb-2">Preview</p>
        <button className="btn-preview-style" onClick={() => preview(props.username)} style={{background: props.color}}>
            <img alt="Revolut Logo" width="32" height="32" src="https://svgur.com/i/Zry.svg"/>
            {props.message}
        </button>
        <div className="mt-4">
            <p className="mb-1" style={{fontSize:'14px', fontWeight:'500'}}>Paste anywhere on the internet</p>
            <div className="col-md-12 preview-code">
                <CopyBlock 
                    showLineNumbers={props.showLineNumbers}
                    startingLineNumber={props.startingLineNumber}
                    text={markup} 
                    language={'html'} 
                    theme={nord} 
                    wrapLines 
                /> 
            </div>
        </div>
    </div>
} 