import React from 'react';

import './Styles/App.scss';

import Create from './Components/Create';
import Preview from './Components/Preview';
import LeftCol from './Components/LeftCol';

function App() {
  const [formData, setFormData] = React.useState({
    username: '',
    color: 'black',
    message: 'Buy me a coffee'
  })

  return (
    <div className="main">
      <div className="container py-5 my-5 content-area">
        <div className="row gx-5">
          <div className="col-md-0 col-lg-1"></div>
          <div className="order-2 order-lg-1 col-md-12 col-lg-4 col-first-mob">
            <LeftCol/>
          </div>
          <div className="order-1 order-lg-2 col-md-12 col-lg-6 preview-content px-5">
            <Create setFormData={setFormData} formData={formData} />
            <Preview {...formData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
