import React from 'react';

import '../Styles/Create.scss';

const styles = {
    label: {
        fontSize:'18px', 
        fontWeight: 500, 
        borderRadius: 0, 
        paddingRight: 0, 
        paddingLeft: 0  
    },
    label1: {
        fontSize: '18px',
        fontWeight: 500
    },
    input: {
        fontSize: '18px', 
        fontWeight: 500, 
        borderRadius: 0, 
        paddingLeft: 0
    }
};

export default function Create(props) {
    return <div className="col-xs-12 col-md-10">
        <form id="create-component">
            <div className="mb-3">
                <h3 className="form-title">Create your button</h3>
            </div>

            <div className="mb-5">
                <div className="input-group">
                    <span className="input-group-text" style={styles.label}>revolut.me/</span>
                    <input
                        type="text"
                        style={styles.input}
                        className="form-control"
                        defaultValue={props.formData.username}
                        placeholder="yourusername"
                        onChange={(e) => props.setFormData({...props.formData, username: e.target.value })} 
                    />
                </div>
                <div className="d-flex flex-column mt-2">
                    <a className="form-text text-primary text-decoration-none" target="_blank" href="https://www.revolut.com/en-US/help/making-payments/sending-money-to-another-revolut-account/username-payments/how-can-i-send-money-by-username">I can't find my Revolut username</a>
                </div>
            </div>
            <div className="mb-5">
                <label className="form-label form-label-sm">Message</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Buy me a coffe"
                    style={styles.input}
                    defaultValue={props.formData.message}
                    onChange={(e) => props.setFormData({...props.formData, message: e.target.value})}
                />
            </div>

            <div className="colors mb-5">
                <div className="d-flex">
                    <label className="form-label" style={styles.label1}>Background color</label>
                    <span className="black" onClick={() => props.setFormData({...props.formData, color: '#000000'})}></span>
                    <span className="blue" onClick={() => props.setFormData({...props.formData, color: '#0d6efd'})}></span>
                </div> 
            </div>
        </form>
    </div>
}