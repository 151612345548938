import React from 'react';

import { preview } from '../Helpers/helpers';

const styles = {
    p: {
        fontSize: '20px', 
        fontWeight: 'bold', 
        letterSpacing: '.4px',
        lineHeight: '26px'
    },
    p1: {
        fontSize: '14px', 
        fontWeight: 500 
    },
    h4: {
        fontSize: '24px', 
        fontWeight: 'bold',
    },
    a: {
        fontSize: '14px', 
        fontWeight: 500, 
        letterSpacing: '1.2px'
    },
    btn: {
        backgroundColor: 'black',
    }
};

export default function LeftCol() {
    return <div>
         <div className="has-radius p-5 box mb-3" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/revolut-popup-element.png'})`}}>
              <p className="text-white" style={styles.p}>Start taking tips directly in your Revolut account. Generate a simple button that you can place anywhere on the web.</p>
              <a className="text-white" style={styles.a} href="https://revolut.com/referral/andreiban!jul1ar">Join Revolut</a>
        </div>
        <div className="bg-white p-5 has-radius mt-5">
            <p style={styles.p1}>Follow me <a className="text-decoration-none" href="https://twitter.com/andreijluca">@andreijluca</a></p>
            <h4 style={styles.h4}>
                Enjoy the generator? 
                A thank you is always appreciated!
            </h4>

            <button className="mt-4 btn-preview-style" style={styles.btn} onClick={() => preview('andreijluca')}>
                <img width="32" height="32" src={process.env.PUBLIC_URL + '/revolut.svg'} alt="Revolut Logo"/>
                Thank You!
            </button>
        </div>
    </div>
}